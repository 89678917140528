import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { withTrans } from "../../i18n/withTrans"
import { slug } from "../../helpers/general"
/** COMPONENTS */
import Layout from "../../components/layout"
import Seo from "../../components/seo"
/** MATERIAL UI */
import Divider from "@material-ui/core/Divider"
import Container from "@material-ui/core/Container"
import { withStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import InputBase from "@material-ui/core/InputBase"
import IconButton from "@material-ui/core/IconButton"
import MuiAccordion from "@material-ui/core/Accordion"
import MuiAccordionSummary from "@material-ui/core/AccordionSummary"
import MuiAccordionDetails from "@material-ui/core/AccordionDetails"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"
import { graphql } from "gatsby"
import "./faq.scss"
import { StaticImage } from "gatsby-plugin-image"

const Accordion = withStyles({
  root: {
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    justifyContent: "space-between",
    display: "flex",
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails)

function FaqPage({ data }) {
  const { t, i18n } = useTranslation()

  // const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  // const classes = useStyles(isMobile);

  const tabs = data.faqstabs.siteMetadata.faqstabs
  const loanApplication = `loan-application`
  const repaymentLoanBills = `repayment-loan-bills`
  const loanDisbursement = `loan-disbursement`
  const accountRegistration = `account-registration`

  const loanTabArr = [
    loanApplication,
    repaymentLoanBills,
    loanDisbursement,
    accountRegistration,
  ]

  const [activeTabs, setActiveTabs] = useState(0)
  const [activeLoanTabs, setActiveLoanTabs] = useState(0)

  const [search, setSearch] = useState("")
  const [typingTimeout, setTypingTimeout] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const onChangeTabs = index => {
    setActiveTabs(index)
  }

  const onChangeTabsLoan = index => {
    setActiveLoanTabs(index)
  }

  const [datas, setDatas] = useState([])

  useEffect(() => {
    const setData = [
      /** FUNDING */
      {
        type: "funding",
        title: t(`faq.funding-q1`),
        content: t(`faq.funding-q1-content`),
        expanded: false,
      },
      {
        type: "funding",
        title: t(`faq.funding-q2`),
        content: t(`faq.funding-q2-content`),
        expanded: false,
      },
      {
        type: "funding",
        title: t(`faq.funding-q3`),
        content: t(`faq.funding-q3-content`),
        expanded: false,
      },
      {
        type: "funding",
        title: t(`faq.funding-q4`),
        content: t(`faq.funding-q4-content`),
        expanded: false,
      },
      {
        type: "funding",
        title: t(`faq.funding-q5`),
        content: t(`faq.funding-q5-content`),
        expanded: false,
      },
      {
        type: "funding",
        title: t(`faq.funding-q6`),
        content: t(`faq.funding-q6-content`),
        expanded: false,
      },
      {
        type: "funding",
        title: t(`faq.funding-q7`),
        content: t(`faq.funding-q7-content`),
        expanded: false,
      },
      {
        type: "funding",
        title: t(`faq.funding-q8`),
        content: t(`faq.funding-q8-content`),
        expanded: false,
      },
      {
        type: "funding",
        title: t(`faq.funding-q9`),
        content: t(`faq.funding-q9-content`),
        expanded: false,
      },
      {
        type: "funding",
        title: t(`faq.funding-q10`),
        content: t(`faq.funding-q10-content`),
        expanded: false,
      },
      {
        type: "funding",
        title: t(`faq.funding-q11`),
        content: t(`faq.funding-q11-content`),
        expanded: false,
      },
      {
        type: "funding",
        title: t(`faq.funding-q12`),
        content: t(`faq.funding-q12-content`),
        expanded: false,
      },
      {
        type: "funding",
        title: t(`faq.funding-q13`),
        content: t(`faq.funding-q13-content`),
        expanded: false,
      },
      {
        type: "funding",
        title: t(`faq.funding-q14`),
        content: t(`faq.funding-q14-content`),
        expanded: false,
      },
      {
        type: "funding",
        title: t(`faq.funding-q15`),
        content: t(`faq.funding-q15-content`),
        expanded: false,
      },
      {
        type: "funding",
        title: t(`faq.funding-q16`),
        content: t(`faq.funding-q16-content`),
        expanded: false,
      },
      {
        type: "funding",
        title: t(`faq.funding-q17`),
        content: t(`faq.funding-q17-content`),
        expanded: false,
      },
      {
        type: "funding",
        title: t(`faq.funding-q18`),
        content: t(`faq.funding-q18-content`),
        expanded: false,
      },

      /** LOAN */
      {
        type: "loan",
        title: t(`faq.loan-q1`),
        content: t(`faq.loan-q1-content`),
        expanded: false,
      },
      {
        type: "loan",
        title: t(`faq.loan-q2`),
        content: t(`faq.loan-q2-content`),
        expanded: false,
      },
      {
        type: "loan",
        title: t(`faq.loan-q3`),
        content: t(`faq.loan-q3-content`),
        expanded: false,
      },
      {
        type: "loan",
        title: t(`faq.loan-q4`),
        content: t(`faq.loan-q4-content`),
        expanded: false,
      },
      {
        type: "loan",
        title: t(`faq.loan-q5`),
        content: t(`faq.loan-q5-content`),
        expanded: false,
      },
      {
        type: "loan",
        title: t(`faq.loan-q6`),
        content: t(`faq.loan-q6-content`),
        expanded: false,
      },
      {
        type: "loan",
        title: t(`faq.loan-q7`),
        content: t(`faq.loan-q7-content`),
        expanded: false,
      },
      {
        type: "loan",
        title: t(`faq.loan-q8`),
        content: t(`faq.loan-q8-content`),
        expanded: false,
      },
      {
        type: "loan",
        title: t(`faq.loan-q9`),
        content: t(`faq.loan-q9-content`),
        expanded: false,
      },
      {
        type: "loan",
        title: t(`faq.loan-q10`),
        content: `${t(`faq.loan-q10-content-1`)}</br>
        ${t(`faq.loan-q10-content-2`)}</br>
           &nbsp; &nbsp; &nbsp; ${t(`faq.loan-q10-content-3`)}</br>
           &nbsp; &nbsp; &nbsp; ${t(`faq.loan-q10-content-4`)}</br>
           &nbsp; &nbsp; &nbsp; ${t(`faq.loan-q10-content-5`)}</br>
           &nbsp; &nbsp; &nbsp; ${t(`faq.loan-q10-content-6`)}</br>
        ${t(`faq.loan-q10-content-7`)}</br>
          &nbsp; &nbsp; &nbsp; ${t(`faq.loan-q10-content-3`)}</br>
          &nbsp; &nbsp; &nbsp; ${t(`faq.loan-q10-content-8`)}</br>
          &nbsp; &nbsp; &nbsp; ${t(`faq.loan-q10-content-6`)}</br>`,
        expanded: false,
      },
      {
        type: "loan",
        title: t(`faq.loan-q11`),
        content: t(`faq.loan-q11-content`),
        expanded: false,
      },
      {
        type: "loan",
        title: t(`faq.loan-q12`),
        content: t(`faq.loan-q12-content`),
        expanded: false,
      },
      {
        type: "loan",
        title: t(`faq.loan-q13`),
        content: t(`faq.loan-q13-content`),
        expanded: false,
      },
      {
        type: "loan",
        title: t(`faq.loan-q14`),
        content: t(`faq.loan-q14-content`),
        expanded: false,
      },
      {
        type: "loan",
        title: t(`faq.loan-q15`),
        content: t(`faq.loan-q15-content`),
        expanded: false,
      },
      {
        type: "loan",
        title: t(`faq.loan-q16`),
        content: t(`faq.loan-q16-content`),
        expanded: false,
      },
      {
        type: "loan",
        title: t(`faq.loan-q17`),
        content: t(`faq.loan-q17-content`),
        expanded: false,
      },

      // LOAN APPLICATION

      {
        type: "loanApplication",
        title: t(`faq.loan-application-q1`),
        content: t(`faq.loan-application-q1-content`),
        expanded: false,
      },
      {
        type: "loanApplication",
        title: t(`faq.loan-application-q2`),
        content: t(`faq.loan-application-q2-content`),
        expanded: false,
      },
      {
        type: "loanApplication",
        title: t(`faq.loan-application-q3`),
        content: t(`faq.loan-application-q3-content`),
        expanded: false,
      },
      {
        type: "loanApplication",
        title: t(`faq.loan-application-q4`),
        content: t(`faq.loan-application-q4-content`),
        expanded: false,
      },
      {
        type: "loanApplication",
        title: t(`faq.loan-application-q5`),
        content: `${t(`faq.loan-application-q5-content`)}</br>
        ${t(`faq.loan-application-q5-content-1`)}</br>
        ${t(`faq.loan-application-q5-content-2`)}</br>`,
        expanded: false,
      },
      {
        type: "loanApplication",
        title: t(`faq.loan-application-q6`),
        content: t(`faq.loan-application-q6-content`),
        expanded: false,
      },
      {
        type: "loanApplication",
        title: t(`faq.loan-application-q7`),
        content: t(`faq.loan-application-q7-content`),
        expanded: false,
      },
      {
        type: "loanApplication",
        title: t(`faq.loan-application-q8`),
        content: t(`faq.loan-application-q8-content`),
        expanded: false,
      },
      {
        type: "loanApplication",
        title: t(`faq.loan-application-q9`),
        content: t(`faq.loan-application-q9-content`),
        expanded: false,
      },
      {
        type: "loanApplication",
        title: t(`faq.loan-application-q10`),
        content: t(`faq.loan-application-q10-content`),
        expanded: false,
      },
      {
        type: "loanApplication",
        title: t(`faq.loan-application-q11`),
        content: t(`faq.loan-application-q11-content`),
        expanded: false,
      },
      {
        type: "loanApplication",
        title: t(`faq.loan-application-q12`),
        content: t(`faq.loan-application-q12-content`),
        expanded: false,
      },
      {
        type: "loanApplication",
        title: t(`faq.loan-application-q13`),
        content: t(`faq.loan-application-q13-content`),
        expanded: false,
      },
      {
        type: "loanApplication",
        title: t(`faq.loan-application-q14`),
        content: t(`faq.loan-application-q14-content`),
        expanded: false,
      },
      {
        type: "loanApplication",
        title: t(`faq.loan-application-q15`),
        content: t(`faq.loan-application-q15-content`),
        expanded: false,
      },

      // LOAN REPAYMENT
      {
        type: "loanRepayment",
        title: t(`faq.repayment-loan-bills-q1`),
        content: t(`faq.repayment-loan-bills-q1-content`),
        expanded: false,
      },
      {
        type: "loanRepayment",
        title: t(`faq.repayment-loan-bills-q2`),
        content: t(`faq.repayment-loan-bills-q2-content`),
        expanded: false,
      },
      {
        type: "loanRepayment",
        title: t(`faq.repayment-loan-bills-q3`),
        content: t(`faq.repayment-loan-bills-q3-content`),
        expanded: false,
      },
      {
        type: "loanRepayment",
        title: t(`faq.repayment-loan-bills-q4`),
        content: t(`faq.repayment-loan-bills-q4-content`),
        expanded: false,
      },
      {
        type: "loanRepayment",
        title: t(`faq.repayment-loan-bills-q5`),
        content: `${t(`faq.repayment-loan-bills-q5-content`)}</br>
        ${t(`faq.repayment-loan-bills-q5-content-1`)}</br>
           &nbsp; &nbsp; &nbsp; ${t(
             `faq.repayment-loan-bills-q5-content-2`
           )}</br>
        ${t(`faq.repayment-loan-bills-q5-content-3`)}</br>
          &nbsp; &nbsp; &nbsp; ${t(
            `faq.repayment-loan-bills-q5-content-4`
          )}</br>
          &nbsp; &nbsp; &nbsp; ${t(
            `faq.repayment-loan-bills-q5-content-5`
          )}</br>
          &nbsp; &nbsp; &nbsp; ${t(
            `faq.repayment-loan-bills-q5-content-6`
          )}</br>
          &nbsp; &nbsp; &nbsp; ${t(
            `faq.repayment-loan-bills-q5-content-7`
          )}</br>
          &nbsp; &nbsp; &nbsp; ${t(
            `faq.repayment-loan-bills-q5-content-8`
          )}</br>
          &nbsp; &nbsp; &nbsp; ${t(
            `faq.repayment-loan-bills-q5-content-9`
          )}</br>
          &nbsp; &nbsp; &nbsp; ${t(
            `faq.repayment-loan-bills-q5-content-10`
          )}</br>
          &nbsp; &nbsp; &nbsp; ${t(
            `faq.repayment-loan-bills-q5-content-11`
          )}</br>`,
        expanded: false,
      },

      // LOAN DISBURSEMENT
      {
        type: "loanDisbursement",
        title: t(`faq.loan-disbursement-q1`),
        content: t(`faq.loan-disbursement-q1-content`),
        expanded: false,
      },

      // ACCOUNT REGISTRATION
      {
        type: "loanAccountRegistration",
        title: t(`faq.account-registration-q1`),
        content: t(`faq.account-registration-q1-content`),
        expanded: false,
      },
      {
        type: "loanAccountRegistration",
        title: t(`faq.account-registration-q2`),
        content: t(`faq.account-registration-q2-content`),
        expanded: false,
      },
      {
        type: "loanAccountRegistration",
        title: t(`faq.account-registration-q3`),
        content: `${t(`faq.account-registration-q3-content`)}</br>
        ${t(`faq.account-registration-q3-content-1`)}</br>
        ${t(`faq.account-registration-q3-content-2`)}</br>
        ${t(`faq.account-registration-q3-content-3`)}</br>`,
        expanded: false,
      },
      {
        type: "loanAccountRegistration",
        title: t(`faq.account-registration-q4`),
        content: t(`faq.account-registration-q4-content`),
        expanded: false,
      },

      /** ACCOUNT */
      {
        type: "account",
        title: t(`faq.account-q1`),
        content: t(`faq.account-q1-content`),
        expanded: false,
      },
      {
        type: "account",
        title: t(`faq.account-q2`),
        content: t(`faq.account-q2-content`),
        expanded: false,
      },
      {
        type: "account",
        title: t(`faq.account-q3`),
        content: t(`faq.account-q3-content`),
        expanded: false,
      },
      {
        type: "account",
        title: t(`faq.account-q4`),
        content: t(`faq.account-q4-content`),
        expanded: false,
      },
      {
        type: "account",
        title: t(`faq.account-q5`),
        content: t(`faq.account-q5-content`),
        expanded: false,
      },
      {
        type: "account",
        title: t(`faq.account-q6`),
        content: t(`faq.account-q6-content`),
        expanded: false,
      },

      /** PRODUCT */
      {
        type: "product",
        title: t(`faq.product-q1`),
        content: t(`faq.product-q1-content`),
        expanded: false,
      },
      {
        type: "product",
        title: t(`faq.product-q2`),
        content: t(`faq.product-q2-content`),
        expanded: false,
      },
      {
        type: "product",
        title: t(`faq.product-q3`),
        content: t(`faq.product-q3-content`),
        expanded: false,
      },
      {
        type: "product",
        title: t(`faq.product-q4`),
        content: t(`faq.product-q4-content`),
        expanded: false,
      },
      {
        type: "product",
        title: t("faq.product-q5"),
        content: t("faq.product-q5-content"),
        expanded: false,
      },

      /** PAYMENT */
      {
        type: "payment",
        title: t(`faq.payment-q1`),
        content: t(`faq.payment-q1-content`),
        expanded: false,
      },
      {
        type: "payment",
        title: t(`faq.payment-q2`),
        content: t(`faq.payment-q2-content`),
        expanded: false,
      },
      {
        type: "payment",
        title: t(`faq.payment-q3`),
        content: t(`faq.payment-q3-content`),
        expanded: false,
      },
      {
        type: "payment",
        title: t(`faq.payment-q4`),
        content: t(`faq.payment-q4-content`),
        expanded: false,
      },
      {
        type: "payment",
        title: t(`faq.payment-q5`),
        content: t(`faq.payment-q5-content`),
        expanded: false,
      },
      {
        type: "payment",
        title: t(`faq.payment-q6`),
        content: t(`faq.payment-q6-content`),
        expanded: false,
      },
    ]
    setDatas(state => [...setData])

    const initialFunding = datasFilter(setData, "funding")
    const initialLoan = datasFilter(setData, "loan")
    const initialAccount = datasFilter(setData, "account")
    const initialProduct = datasFilter(setData, "product")
    const initialPayment = datasFilter(setData, "payment")

    const initialLoanApplication = datasFilter(setData, "loanApplication")
    const initialLoanRepayment = datasFilter(setData, "loanRepayment")
    const initialLoanDisbursement = datasFilter(setData, "loanDisbursement")
    const initialAccountRegistration = datasFilter(
      setData,
      "loanAccountRegistration"
    )

    setDatasFunding(initialFunding)
    setDatasLoan(initialLoan)
    setDatasAccount(initialAccount)
    setDatasProduct(initialProduct)
    setDatasPayment(initialPayment)

    setDatasLoanApplication(initialLoanApplication)
    setDatasLoanRepayment(initialLoanRepayment)
    setDatasLoanDisbursement(initialLoanDisbursement)
    setDatasAccountRegistration(initialAccountRegistration)
  }, [t, i18n.language])

  const datasFilter = (datasParam, type) => {
    return datasParam.filter(item => {
      return item.type === type
    })
  }

  const initialFunding = datasFilter(datas, "funding")
  const initialLoan = datasFilter(datas, "loan")
  const initialAccount = datasFilter(datas, "account")
  const initialProduct = datasFilter(datas, "product")
  const initialPayment = datasFilter(datas, "payment")

  const initialLoanApplication = datasFilter(datas, "loanApplication")
  const initialLoanRepayment = datasFilter(datas, "loanRepayment")
  const initialLoanDisbursement = datasFilter(datas, "loanDisbursement")
  const initialAccountRegistration = datasFilter(
    datas,
    "loanAccountRegistration"
  )

  const [datasFunding, setDatasFunding] = useState(initialFunding)
  const [datasLoan, setDatasLoan] = useState(initialLoan)
  const [datasAccount, setDatasAccount] = useState(initialAccount)
  const [datasProduct, setDatasProduct] = useState(initialProduct)
  const [datasPayment, setDatasPayment] = useState(initialPayment)

  const [datasLoanApplication, setDatasLoanApplication] = useState(
    initialLoanApplication
  )
  const [datasLoanRepayment, setDatasLoanRepayment] = useState(
    initialLoanRepayment
  )
  const [datasLoanDisbursement, setDatasLoanDisbursement] = useState(
    initialLoanDisbursement
  )
  const [datasAccountRegistration, setDatasAccountRegistration] = useState(
    initialAccountRegistration
  )

  const handleChangeSearch = event => {
    const value = event.target.value
    //search = event.target.value

    setSearch(value)
    if (typingTimeout) {
      clearTimeout(typingTimeout)
    }

    setIsLoading(true)
    setTypingTimeout(
      setTimeout(() => {
        let virDatas = datas
        virDatas = virDatas.filter(item => {
          if (
            item.title
              .toLowerCase(value.toLowerCase())
              .includes(value.toLowerCase())
          ) {
            return true
          } else if (
            item.content.toLowerCase(value.toLowerCase()).includes(value)
          ) {
            return true
          }

          return false
        })

        setDatasFunding(datasFilter(virDatas, "funding"))
        setDatasLoan(datasFilter(virDatas, "loan"))
        setDatasAccount(datasFilter(virDatas, "account"))
        setDatasProduct(datasFilter(virDatas, "product"))
        setDatasPayment(datasFilter(virDatas, "payment"))
        setDatasLoanApplication(datasFilter(virDatas, "loanApplication"))
        setDatasLoanRepayment(datasFilter(virDatas, "loanRepayment"))
        setDatasLoanDisbursement(datasFilter(virDatas, "loanDisbursement"))
        setDatasAccountRegistration(
          datasFilter(virDatas, "loanAccountRegistration")
        )
        setIsLoading(false)
      }, 700)
    )
  }

  const handleChangeQ = (type, index) => {
    if (type === "funding") {
      const virDatas = datasFunding

      virDatas[index].expanded = !virDatas[index].expanded

      setDatasFunding(state => [...virDatas])
    } else if (type === "loan") {
      const virDatas = datasLoan

      virDatas[index].expanded = !virDatas[index].expanded

      setDatasLoan(state => [...virDatas])
    } else if (type === "account") {
      const virDatas = datasAccount

      virDatas[index].expanded = !virDatas[index].expanded

      setDatasAccount(state => [...virDatas])
    } else if (type === "product") {
      const virDatas = datasProduct

      virDatas[index].expanded = !virDatas[index].expanded

      setDatasProduct(state => [...virDatas])
    } else if (type === "payment") {
      const virDatas = datasPayment

      virDatas[index].expanded = !virDatas[index].expanded

      setDatasPayment(state => [...virDatas])
    } else if (type === "loanApplication") {
      const virDatas = datasLoanApplication

      virDatas[index].expanded = !virDatas[index].expanded

      setDatasLoanApplication(state => [...virDatas])
    } else if (type === "loanRepayment") {
      const virDatas = datasLoanRepayment

      virDatas[index].expanded = !virDatas[index].expanded

      setDatasLoanRepayment(state => [...virDatas])
    } else if (type === "loanDisbursement") {
      const virDatas = datasLoanDisbursement

      virDatas[index].expanded = !virDatas[index].expanded

      setDatasLoanDisbursement(state => [...virDatas])
    } else if (type === "loanAccountRegistration") {
      const virDatas = datasAccountRegistration

      virDatas[index].expanded = !virDatas[index].expanded

      setDatasAccountRegistration(state => [...virDatas])
    }
  }

  return (
    <Layout>
      <Seo title="FAQ (Frequently Askes Questions)" />
      {/* SECTION BANNER TOP */}
      <div className="header-content">
        <Container className="header-container">
          <div className="header-content-text">
            {/* <TextField
              className={classes.textSearch}

              InputProps={{
                endAdornment: (<img src={IconSearch} width={24} />)
              }}
            /> */}
            <Paper component="form" className="paper-text-search">
              <InputBase
                placeholder={t(`general.search`)}
                inputProps={{ "aria-label": "search faq" }}
                className="text-search"
                onChange={handleChangeSearch}
              />
              <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
                <StaticImage
                  src="../../assets/images/icons/loupe-reverse.png"
                  className="search-icon"
                  alt=""
                />
              </IconButton>
            </Paper>
          </div>
        </Container>

        {/* SECTION FAQ */}
        <div className="faq-content">
          <Container className="faq-container">
            <div className="faq-content-tabs">
              {tabs.map((item, index) => {
                const isActive = index === activeTabs
                return (
                  <span
                    aria-hidden="true"
                    key={index}
                    className={isActive ? "faq-content-tabs-active" : ""}
                    onClick={() => {
                      onChangeTabs(index)
                    }}
                    onKeyDown={() => {
                      onChangeTabs(index)
                    }}
                  >
                    {t(`faq.${slug(item)}`)}
                  </span>
                )
              })}
            </div>

            {isLoading ? (
              <div className="root">
                <CircularProgress color="inherit" />
              </div>
            ) : (
              <div className="faq-content-tabs-body">
                <>
                  {activeTabs === 0 && (
                    <>
                      {datasFunding.length > 0 ? (
                        datasFunding.map((item, index) => {
                          return (
                            <div key={index}>
                              <Accordion
                                expanded={item.expanded}
                                onChange={e => {
                                  handleChangeQ(item.type, index)
                                }}
                                style={{
                                  marginTop: 5,
                                  marginBottom: 5,
                                  borderRadius: 15,
                                  background: "transparent",
                                }}
                              >
                                <AccordionSummary
                                  className={
                                    item.expanded
                                      ? "accordion-expanded"
                                      : "accordion"
                                  }
                                >
                                  <div className="accordion-image">
                                    {item.expanded ? (
                                      <StaticImage
                                        src="../../assets/images/icons/minus.png"
                                        width={25}
                                        style={{ margin: 3 }}
                                        alt=""
                                      />
                                    ) : (
                                      <StaticImage
                                        src="../../assets/images/icons/plus.png"
                                        width={25}
                                        style={{ margin: 3 }}
                                        alt=""
                                      />
                                    )}
                                  </div>
                                  <div className="accordion-title">
                                    <Typography>{item.title}</Typography>
                                  </div>
                                </AccordionSummary>
                                <AccordionDetails
                                  style={{ background: "#fff" }}
                                >
                                  <div className="accordion-content">
                                    <Typography
                                      dangerouslySetInnerHTML={{
                                        __html: item.content,
                                      }}
                                    />
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                              <Divider />
                            </div>
                          )
                        })
                      ) : (
                        <>
                          <div style={{ textAlign: "center" }}>
                            <Typography>
                              {t(`general.data-not-found`)}
                            </Typography>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {activeTabs === 1 && (
                    <>
                      <div
                        className="faq-content-tabs"
                        style={{
                          width: "95%",
                          lineHeight: "1.5",
                        }}
                      >
                        {loanTabArr.map((item, index) => {
                          const isActive = index === activeLoanTabs
                          return (
                            <span
                              aria-hidden="true"
                              key={index}
                              className={
                                isActive ? "faq-content-tabs-loan-active" : ""
                              }
                              style={{ marginBottom: "18px" }}
                              onClick={() => {
                                onChangeTabsLoan(index)
                              }}
                              onKeyDown={() => {
                                onChangeTabsLoan(index)
                              }}
                            >
                              {t(`faq.${slug(item)}`)}
                            </span>
                          )
                        })}
                      </div>
                      {/* {datasLoan.length > 0 ? (
                        datasLoan.map((item, index) => {
                          return (
                            <div key={index}>
                              <Accordion
                                expanded={item.expanded}
                                onChange={e => {
                                  handleChangeQ(item.type, index)
                                }}
                                style={{
                                  marginTop: 5,
                                  marginBottom: 5,
                                  borderRadius: 15,
                                  background: "transparent",
                                }}
                              >
                                <AccordionSummary
                                  className={
                                    item.expanded
                                      ? "accordion-expanded"
                                      : "accordion"
                                  }
                                >
                                  <div className="accordion-image">
                                    {item.expanded ? (
                                      <StaticImage
                                        src="../../assets/images/icons/minus.png"
                                        width={25}
                                        style={{ margin: 3 }}
                                        alt=""
                                      />
                                    ) : (
                                      <StaticImage
                                        src="../../assets/images/icons/plus.png"
                                        width={25}
                                        style={{ margin: 3 }}
                                        alt=""
                                      />
                                    )}
                                  </div>
                                  <div className="accordion-title">
                                    <Typography>{item.title}</Typography>
                                  </div>
                                </AccordionSummary>
                                <AccordionDetails
                                  style={{ background: "#fff" }}
                                >
                                  <div className="accordion-content">
                                    <Typography
                                      dangerouslySetInnerHTML={{
                                        __html: item.content,
                                      }}
                                    />
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                              <Divider />
                            </div>
                          )
                        })
                      ) : (
                        <>
                          <div style={{ textAlign: "center" }}>
                            <Typography>
                              {t(`general.data-not-found`)}
                            </Typography>
                          </div>
                        </>
                      )} */}
                    </>
                  )}

                  {activeTabs === 2 && (
                    <>
                      {datasAccount.length > 0 ? (
                        datasAccount.map((item, index) => {
                          return (
                            <div key={index}>
                              <Accordion
                                expanded={item.expanded}
                                onChange={e => {
                                  handleChangeQ(item.type, index)
                                }}
                                style={{
                                  marginTop: 5,
                                  marginBottom: 5,
                                  borderRadius: 15,
                                  background: "transparent",
                                }}
                              >
                                <AccordionSummary
                                  className={
                                    item.expanded
                                      ? "accordion-expanded"
                                      : "accordion"
                                  }
                                >
                                  <div className="accordion-image">
                                    {item.expanded ? (
                                      <StaticImage
                                        src="../../assets/images/icons/minus.png"
                                        width={25}
                                        style={{ margin: 3 }}
                                        alt=""
                                      />
                                    ) : (
                                      <StaticImage
                                        src="../../assets/images/icons/plus.png"
                                        width={25}
                                        style={{ margin: 3 }}
                                        alt=""
                                      />
                                    )}
                                  </div>
                                  <div className="accordion-title">
                                    <Typography>{item.title}</Typography>
                                  </div>
                                </AccordionSummary>
                                <AccordionDetails
                                  style={{ background: "#fff" }}
                                >
                                  <div className="accordion-content">
                                    <Typography
                                      dangerouslySetInnerHTML={{
                                        __html: item.content,
                                      }}
                                    />
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                              <Divider />
                            </div>
                          )
                        })
                      ) : (
                        <>
                          <div style={{ textAlign: "center" }}>
                            <Typography>
                              {t(`general.data-not-found`)}
                            </Typography>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {activeTabs === 3 && (
                    <>
                      {datasProduct.length > 0 ? (
                        datasProduct.map((item, index) => {
                          return (
                            <div key={index}>
                              <Accordion
                                expanded={item.expanded}
                                onChange={e => {
                                  handleChangeQ(item.type, index)
                                }}
                                style={{
                                  marginTop: 5,
                                  marginBottom: 5,
                                  borderRadius: 15,
                                  background: "transparent",
                                }}
                              >
                                <AccordionSummary
                                  className={
                                    item.expanded
                                      ? "accordion-expanded"
                                      : "accordion"
                                  }
                                >
                                  <div className="accordion-image">
                                    {item.expanded ? (
                                      <StaticImage
                                        src="../../assets/images/icons/minus.png"
                                        width={25}
                                        style={{ margin: 3 }}
                                        alt=""
                                      />
                                    ) : (
                                      <StaticImage
                                        src="../../assets/images/icons/plus.png"
                                        width={25}
                                        style={{ margin: 3 }}
                                        alt=""
                                      />
                                    )}
                                  </div>
                                  <div className="accordion-title">
                                    <Typography>{item.title}</Typography>
                                  </div>
                                </AccordionSummary>
                                <AccordionDetails
                                  style={{ background: "#fff" }}
                                >
                                  <div className="accordion-content">
                                    <Typography
                                      dangerouslySetInnerHTML={{
                                        __html: item.content,
                                      }}
                                    />
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                              <Divider />
                            </div>
                          )
                        })
                      ) : (
                        <>
                          <div style={{ textAlign: "center" }}>
                            <Typography>
                              {t(`general.data-not-found`)}
                            </Typography>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {activeTabs === 4 && (
                    <>
                      {datasPayment.length > 0 ? (
                        datasPayment.map((item, index) => {
                          return (
                            <div key={index}>
                              <Accordion
                                expanded={item.expanded}
                                onChange={e => {
                                  handleChangeQ(item.type, index)
                                }}
                                style={{
                                  marginTop: 5,
                                  marginBottom: 5,
                                  borderRadius: 15,
                                  background: "transparent",
                                }}
                              >
                                <AccordionSummary
                                  className={
                                    item.expanded
                                      ? "accordion-expanded"
                                      : "accordion"
                                  }
                                >
                                  <div className="accordion-image">
                                    {item.expanded ? (
                                      <StaticImage
                                        src="../../assets/images/icons/minus.png"
                                        width={25}
                                        style={{ margin: 3 }}
                                        alt=""
                                      />
                                    ) : (
                                      <StaticImage
                                        src="../../assets/images/icons/plus.png"
                                        width={25}
                                        style={{ margin: 3 }}
                                        alt=""
                                      />
                                    )}
                                  </div>
                                  <div className="accordion-title">
                                    <Typography>{item.title}</Typography>
                                  </div>
                                </AccordionSummary>
                                <AccordionDetails
                                  style={{ background: "#fff" }}
                                >
                                  <div className="accordion-content">
                                    <Typography
                                      dangerouslySetInnerHTML={{
                                        __html: item.content,
                                      }}
                                    />
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                              <Divider />
                            </div>
                          )
                        })
                      ) : (
                        <>
                          <div style={{ textAlign: "center" }}>
                            <Typography>
                              {t(`general.data-not-found`)}
                            </Typography>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {activeTabs !== 1 ? (
                    <></>
                  ) : (
                    <>
                      {activeLoanTabs === 0 && (
                        <>
                          {datasLoanApplication.length > 0 ? (
                            datasLoanApplication.map((item, index) => {
                              return (
                                <div key={index}>
                                  <Accordion
                                    expanded={item.expanded}
                                    onChange={e => {
                                      handleChangeQ(item.type, index)
                                    }}
                                    style={{
                                      marginTop: 5,
                                      marginBottom: 5,
                                      borderRadius: 15,
                                      background: "transparent",
                                    }}
                                  >
                                    <AccordionSummary
                                      className={
                                        item.expanded
                                          ? "accordion-expanded"
                                          : "accordion"
                                      }
                                    >
                                      <div className="accordion-image">
                                        {item.expanded ? (
                                          <StaticImage
                                            src="../../assets/images/icons/minus.png"
                                            width={25}
                                            style={{ margin: 3 }}
                                            alt=""
                                          />
                                        ) : (
                                          <StaticImage
                                            src="../../assets/images/icons/plus.png"
                                            width={25}
                                            style={{ margin: 3 }}
                                            alt=""
                                          />
                                        )}
                                      </div>
                                      <div className="accordion-title">
                                        <Typography>{item.title}</Typography>
                                      </div>
                                    </AccordionSummary>
                                    <AccordionDetails
                                      style={{ background: "#fff" }}
                                    >
                                      <div className="accordion-content">
                                        <Typography
                                          dangerouslySetInnerHTML={{
                                            __html: item.content,
                                          }}
                                        />
                                      </div>
                                    </AccordionDetails>
                                  </Accordion>
                                  <Divider />
                                </div>
                              )
                            })
                          ) : (
                            <>
                              <div style={{ textAlign: "center" }}>
                                <Typography>
                                  {t(`general.data-not-found`)}
                                </Typography>
                              </div>
                            </>
                          )}
                        </>
                      )}

                      {activeLoanTabs === 1 && (
                        <>
                          {datasLoanRepayment.length > 0 ? (
                            datasLoanRepayment.map((item, index) => {
                              return (
                                <div key={index}>
                                  <Accordion
                                    expanded={item.expanded}
                                    onChange={e => {
                                      handleChangeQ(item.type, index)
                                    }}
                                    style={{
                                      marginTop: 5,
                                      marginBottom: 5,
                                      borderRadius: 15,
                                      background: "transparent",
                                    }}
                                  >
                                    <AccordionSummary
                                      className={
                                        item.expanded
                                          ? "accordion-expanded"
                                          : "accordion"
                                      }
                                    >
                                      <div className="accordion-image">
                                        {item.expanded ? (
                                          <StaticImage
                                            src="../../assets/images/icons/minus.png"
                                            width={25}
                                            style={{ margin: 3 }}
                                            alt=""
                                          />
                                        ) : (
                                          <StaticImage
                                            src="../../assets/images/icons/plus.png"
                                            width={25}
                                            style={{ margin: 3 }}
                                            alt=""
                                          />
                                        )}
                                      </div>
                                      <div className="accordion-title">
                                        <Typography>{item.title}</Typography>
                                      </div>
                                    </AccordionSummary>
                                    <AccordionDetails
                                      style={{ background: "#fff" }}
                                    >
                                      <div className="accordion-content">
                                        <Typography
                                          dangerouslySetInnerHTML={{
                                            __html: item.content,
                                          }}
                                        />
                                      </div>
                                    </AccordionDetails>
                                  </Accordion>
                                  <Divider />
                                </div>
                              )
                            })
                          ) : (
                            <>
                              <div style={{ textAlign: "center" }}>
                                <Typography>
                                  {t(`general.data-not-found`)}
                                </Typography>
                              </div>
                            </>
                          )}
                        </>
                      )}

                      {activeLoanTabs === 2 && (
                        <>
                          {datasLoanDisbursement.length > 0 ? (
                            datasLoanDisbursement.map((item, index) => {
                              return (
                                <div key={index}>
                                  <Accordion
                                    expanded={item.expanded}
                                    onChange={e => {
                                      handleChangeQ(item.type, index)
                                    }}
                                    style={{
                                      marginTop: 5,
                                      marginBottom: 5,
                                      borderRadius: 15,
                                      background: "transparent",
                                    }}
                                  >
                                    <AccordionSummary
                                      className={
                                        item.expanded
                                          ? "accordion-expanded"
                                          : "accordion"
                                      }
                                    >
                                      <div className="accordion-image">
                                        {item.expanded ? (
                                          <StaticImage
                                            src="../../assets/images/icons/minus.png"
                                            width={25}
                                            style={{ margin: 3 }}
                                            alt=""
                                          />
                                        ) : (
                                          <StaticImage
                                            src="../../assets/images/icons/plus.png"
                                            width={25}
                                            style={{ margin: 3 }}
                                            alt=""
                                          />
                                        )}
                                      </div>
                                      <div className="accordion-title">
                                        <Typography>{item.title}</Typography>
                                      </div>
                                    </AccordionSummary>
                                    <AccordionDetails
                                      style={{ background: "#fff" }}
                                    >
                                      <div className="accordion-content">
                                        <Typography
                                          dangerouslySetInnerHTML={{
                                            __html: item.content,
                                          }}
                                        />
                                      </div>
                                    </AccordionDetails>
                                  </Accordion>
                                  <Divider />
                                </div>
                              )
                            })
                          ) : (
                            <>
                              <div style={{ textAlign: "center" }}>
                                <Typography>
                                  {t(`general.data-not-found`)}
                                </Typography>
                              </div>
                            </>
                          )}
                        </>
                      )}

                      {activeLoanTabs === 3 && (
                        <>
                          {datasAccountRegistration.length > 0 ? (
                            datasAccountRegistration.map((item, index) => {
                              return (
                                <div key={index}>
                                  <Accordion
                                    expanded={item.expanded}
                                    onChange={e => {
                                      handleChangeQ(item.type, index)
                                    }}
                                    style={{
                                      marginTop: 5,
                                      marginBottom: 5,
                                      borderRadius: 15,
                                      background: "transparent",
                                    }}
                                  >
                                    <AccordionSummary
                                      className={
                                        item.expanded
                                          ? "accordion-expanded"
                                          : "accordion"
                                      }
                                    >
                                      <div className="accordion-image">
                                        {item.expanded ? (
                                          <StaticImage
                                            src="../../assets/images/icons/minus.png"
                                            width={25}
                                            style={{ margin: 3 }}
                                            alt=""
                                          />
                                        ) : (
                                          <StaticImage
                                            src="../../assets/images/icons/plus.png"
                                            width={25}
                                            style={{ margin: 3 }}
                                            alt=""
                                          />
                                        )}
                                      </div>
                                      <div className="accordion-title">
                                        <Typography>{item.title}</Typography>
                                      </div>
                                    </AccordionSummary>
                                    <AccordionDetails
                                      style={{ background: "#fff" }}
                                    >
                                      <div className="accordion-content">
                                        <Typography
                                          dangerouslySetInnerHTML={{
                                            __html: item.content,
                                          }}
                                        />
                                      </div>
                                    </AccordionDetails>
                                  </Accordion>
                                  <Divider />
                                </div>
                              )
                            })
                          ) : (
                            <>
                              <div style={{ textAlign: "center" }}>
                                <Typography>
                                  {t(`general.data-not-found`)}
                                </Typography>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              </div>
            )}
          </Container>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query SiteTabFaqs {
    faqstabs: site {
      siteMetadata {
        faqstabs
      }
    }
  }
`

export default withTrans(FaqPage)
